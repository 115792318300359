import Vue from "vue";
import Vuex from "vuex";

import EquipmentService from "./services/equipment";
import ConnectorService from "./services/connector.js";
import EquipmentDataService from "./services/equipment-data";
import EquipmentAlarmService from "./services/equipment-alarm";
import DashboardService from "./services/dashboard";

import UserModule from "./modules/user";
import RoleModule from "./modules/role";
import DashboardModule from "./modules/dashboard";
import EquipmentModule from "./modules/equipment";
import EquipmentDataModule from "./modules/equipment-data";
import ProcessAreaModule from "@/modules/process-area";
import CommandModule from "@/modules/command.js";
import SynopticModule from "@/modules/synoptic";
import HistoryModule from "@/modules/history.js";
import TasksModule from "@/modules/tasks.js";
import OnlineeModule from "@/modules/onlinee.js";
import ScriptsModule from "@/modules/scripts.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: UserModule,
    role: RoleModule,
    dashboard: DashboardModule,
    equipmentData: EquipmentDataModule,
    equipment: EquipmentModule,
    processArea: ProcessAreaModule,
    command: CommandModule,
    synoptic: SynopticModule,
    history: HistoryModule,
    tasks: TasksModule,
    onlinee: OnlineeModule,
    scripts: ScriptsModule
  },
  state: {
    packageVersion: process.env.VUE_APP_VERSION || "0",
    equimentStatus: "IDLE",
    isLoadingAlarm: false,
    equipmentList: null,
    currentPage: null,
    equipmentDataList: null,
    equipmentDataListLoadingId: 0,
    alarmsReady: false,
    alarmsQuery: null,
    equipmentId: "",
    deviceId: "",
    print: null,
    broker: {
      status: ""
    }
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
    isIdle: (state) => {
      return state.equimentStatus == "IDLE";
    },
    isLoading: (state) => {
      return state.equimentStatus == "BUSY";
    },
    isReady: (state) => {
      return state.equimentStatus == "READY";
    },
    hasError: (state) => {
      return state.equimentStatus == "ERROR";
    },
    equipmentId: (state) => {
      return state.equipmentId;
    },
    deviceId: (state) => {
      return state.deviceId;
    },
    equipmentList: (state, getters, rootState, rootGetters) => {
      // return rootGetters["dashboard/connectorList"] || [];
      return (rootGetters["dashboard/connectorList"] || []).map((connector) => {
        return {
          ...connector,
          ...{
            devices: (rootGetters["dashboard/deviceList"] || []).filter(
              ({connector_id}) => connector_id == connector.id
            )
          }
        };
      });
    },
    currentPage: (state) => {
      return state.currentPage;
    },
    equipmentById: (state, getters, rootState, rootGetters) => {
      // return (keyword) => {
      //   let lst = (state.equipmentList || []).filter((item) => {
      //     return item.id === keyword;
      //   });
      //   return (lst && lst.length && lst[0]) || null;
      // };
      return (connectorId) => {
        return (rootGetters["dashboard/connectorList"] || []).find(
          ({id}) => id == connectorId
        );
      };
    },
    equipmentDataList: (state) => {
      return state.equipmentDataList;
    },
    extendedEquipmentDataList: (state) => {
      let equipmentDataList = state.equipmentDataList || [];
      let connector_id = equipmentDataList.length
        ? state.equipmentDataList[0]?.clp_id || 0
        : 0;
      // console.log(connector_id);
      let valid_list =
        Vue?.http?.options?.config?.data_extended_properties || [];
      let next_device_id = -1;
      let connector = connector_id
        ? state.equipmentList.find((i) => i.id == connector_id) || {}
        : {};
      let connector_lst = [];
      for (var prop in connector) {
        let id = "connector_" + prop;
        if (
          typeof connector[prop] !== "object" &&
          (valid_list.length ? valid_list.indexOf(id) >= 0 : true)
        ) {
          connector_lst.push({
            id: id,
            name: id,
            current_value: {
              date_time: new Date().toISOString(),
              id: id,
              value: connector[prop]
            },
            read_only: true,
            reference_id: id,
            history_enabled: false,
            device: {
              id: next_device_id,
              name: "connector",
              priority: 3
            },
            priority: 1
          });
        }
      }
      let devices = {};
      let device_list = [];
      return (equipmentDataList || [])
        .map((i) => {
          i.priority = 1;
          if (!(i.device.id in devices)) {
            next_device_id -= 1;
            let lst = [];
            for (var prop in i.device) {
              let generic_id = `device_${prop}`;
              if (
                typeof i.device[prop] !== "object" &&
                (valid_list.length ? valid_list.indexOf(generic_id) >= 0 : true)
              ) {
                let id = `device_${i.device.id}_${prop}`;
                lst.push({
                  id: id,
                  name: `device_${prop}`,
                  current_value: {
                    date_time: new Date().toISOString(),
                    id: id,
                    value: i.device[prop]
                  },
                  read_only: true,
                  reference_id: id,
                  history_enabled: false,
                  device: {
                    id: next_device_id,
                    name: "device_" + i.device.id,
                    priority: 2
                  },
                  priority: 1
                });
              }
            }
            devices[i.device.id] = true;
            device_list = device_list.concat(lst);
          }
          return i;
        })
        .concat(connector_lst)
        .concat(device_list);

      //return state.equipmentDataList;
    },
    isEquipmentDataListLoading: (state) => {
      return state.equipmentDataListLoadingId != 0;
    },
    equipmentDataListLoadingId: (state) => {
      return state.equipmentDataListLoadingId;
    },
    alarmsReady: (state) => {
      return state.alarmsReady;
    },
    alarmsQuery: (state) => {
      return state.alarmsQuery;
    },
    print: (state) => {
      return state.print;
    },
    systemProperties: (state, getters, rootState, rootGetters) => {
      let interval = rootGetters["history/interval"] || {
        start: "",
        end: ""
      };
      let createdAt = rootGetters["history/createdAt"] || "";
      return {
        user: rootGetters["user/basicProfile"],
        history: {
          start: interval?.start?._d?.getTime() || "",
          end: interval?.end?._d?.getTime() || "",
          created: createdAt?._d?.getTime() || ""
        },
        broker: state.broker
      };
    },
    locale: (state) => {
      return window.app.__vue__.$i18n.locale;
    },
    brokerStatus: (state) => state.broker.status
  },
  mutations: {
    SET_EQUIPMENT_ID(state, id) {
      Vue.set(state, "equipmentId", id || "");
    },
    SET_DEVICE_ID(state, id) {
      Vue.set(state, "deviceId", id || "");
    },
    SET_EQUIPMENT_STATUS(state, option) {
      // console.log(option);
      state.equimentStatus = option;
    },
    SET_EQUIPMENT_LIST(state, data) {
      Vue.set(state, "equipmentList", data);
    },
    SET_CURRENT_PAGE(state, data) {
      Vue.set(state, "currentPage", data);
    },
    SET_EQUIPMENT_DATA_LIST(state, data) {
      Vue.set(state, "equipmentDataList", data);
    },
    SET_EQUIPMENT_DATA_LIST_LOADING_ID(state, value) {
      Vue.set(state, "equipmentDataListLoadingId", value);
    },
    SET_ALARMS_READY(state, data) {
      Vue.set(state, "alarmsReady", data);
    },
    SET_ALARMS_LOADING(state, data) {
      Vue.set(state, "isLoadingAlarm", data);
    },
    SET_DATA_VALUE(state, entry) {
      //
      if (entry) {
        let data = (state.equipmentDataList || []).find(
          (i) => i.id == entry.id
        );
        if (data) {
          let current_value = {
            id: data.id,
            value: entry.value,
            date_time: new Date().toISOString()
          };
          Vue.set(data, "current_value", current_value);
        }
      }
    },
    RESTORE_DATA_VALUE(state, idList) {
      if (idList && idList.length) {
        (state.equipmentDataList || [])
          .filter((data) => idList.indexOf(data.id) >= 0 && data.restore)
          .forEach((data) => {
            Vue.set(
              data,
              "current_value",
              JSON.parse(JSON.stringify(data.restore))
            );
          });
      }
    },
    SET_EQUIPMENT_ALARMS_CFG(state, data) {
      if (state.equipmentList) {
        var lst = state.equipmentList.filter(function(i) {
          return i.id == data.id;
        });
        if (lst.length) {
          // according to https://vuex.vuejs.org/guide/mutations.html
          // adding a new property should use set
          let isAlarmed =
            data.alarms.filter(function(i) {
              return i.estado;
            }).length > 0;
          for (var i in lst) {
            Vue.set(lst[i], "alarms", data.alarms);
            Vue.set(lst[i], "isAlarmed", isAlarmed);
          }
        }
      }
    },
    SET_EQUIPMENT_NOTIFICATION(state, option) {
      if (option) {
        var lst = state.equipmentList.filter(function(i) {
          return i.id == option.equipment.id;
        });
        var equipment = (lst.length && lst[0]) || null;
        if (equipment) {
          Vue.set(equipment, "notifications", option.notifications);
        }
      }
    },
    SET_BASIC_EQUIPMENT_PROPERTIES(state, option) {
      if (option && option.equipment) {
        let ixConnector = (state.equipmentList || []).findIndex(
          (i) => i.id == option.equipment.id
        );
        if (ixConnector > -1) {
          let equipment = state.equipmentList[ixConnector];
          if ("device" in option && option.device) {
            delete option.device.connector;
            let devices = equipment.devices || [];
            let ixDevice = devices.findIndex((i) => i.id == option.device.id);
            if (ixDevice > -1) {
              Object.assign(devices[ixDevice], option.device);
            } else {
              devices.push(option.device);
            }
            Object.assign(equipment, option.equipment);
            equipment.devices = devices;
          } else {
            Object.assign(equipment, option.equipment);
          }
        }
      }
    },
    RESET_DISPLAY(state, entry) {
      let equipment = (state.equipmentList || []).find(
        (i) => i.id == entry.equipmentId
      );
      if (equipment) {
        Vue.set(equipment, "display", null);
      }
    },
    SET_DISPLAY(state, entry) {
      let self = this;
      let equipment = (state.equipmentList || []).find(
        (i) => i.id == entry.equipmentId
      );
      if (equipment) {
        let data = {
          data_id: {}
        };
        if (state.equipmentDataList && state.equipmentDataList.length) {
          /* 
          search order: reference_id, device+name, name
          */
          for (let i in state.equipmentDataList) {
            data.data_id[state.equipmentDataList[i].reference_id] =
              state.equipmentDataList[i].id;
            data.data_id[
              state.equipmentDataList[i].device.name +
                "." +
                state.equipmentDataList[i].name
            ] = state.equipmentDataList[i].id;
            data.data_id[state.equipmentDataList[i].name] =
              state.equipmentDataList[i].id;
          }
        }
        // parse assigned template
        let srv = new DashboardService();
        let cfg = (Vue.http.options && Vue.http.options.config) || {};
        let screenId =
          ("screenId" in entry ? entry.screenId : equipment.screen_id) ||
          cfg.screen_id ||
          0;

        let mode = self.getters["dashboard/mode"];
        let template = null;
        if (mode == "editor") {
          template = self.getters["dashboard/editorTemplate"];
        } else {
          template = self.getters["dashboard/template"](screenId);
        }
        let display = srv.renderDashboardConfiguration(template, data);
        if (display) {
          display.id = screenId;
          Vue.set(equipment, "display", display);
          if (template && equipment.screen_id != screenId) {
            Vue.set(equipment, "screen_id", screenId);
          }
        }
      }
    },
    SET_PRINT(state, value) {
      state.print = value;
      // console.log((state.print && state.print.count) || 0);
    },
    SET_BROKER_STATUS(state, value) {
      state.broker.status = value;
    }
  },
  actions: {
    setEquipmentId(context, value) {
      context.commit("SET_EQUIPMENT_ID", value);
    },

    reset(context) {
      context.commit("SET_EQUIPMENT_LIST", null);
      context.commit("SET_CURRENT_PAGE", null);
      context.commit("SET_EQUIPMENT_DATA_LIST", null);
      context.commit("SET_ALARMS_READY", false);
      context.dispatch("unselectEquipment");
      context.dispatch("dashboard/reset");
    },

    unselectEquipment(context) {
      context.commit("SET_EQUIPMENT_ID", "");
      context.commit("SET_DEVICE_ID", "");
      // context.commit("dashboard/SET_MODE", "viewer");
    },

    resetEquipmentData(context) {
      context.commit("SET_EQUIPMENT_DATA_LIST", null);
    },

    fetchEquipmentList(context) {
      if (context.state.equimentStatus == "BUSY") return;
      context.commit("SET_EQUIPMENT_STATUS", "BUSY");
      return new Promise((resolve) => {
        context
          .dispatch("dashboard/fetchDevices")
          .then((result) => {
            context.commit("SET_EQUIPMENT_STATUS", "READY");
            resolve(result);
          })
          .catch((e) => {
            context.commit("SET_EQUIPMENT_STATUS", "ERROR");
          });
      });
    },

    parseDataConnector(context, dataList) {
      let srvEquipment = new EquipmentService();
      let srvConnector = new ConnectorService();
      let inserted = {};
      (dataList || []).forEach((item) => {
        let device = item?.device || null;
        if (device && device?.connector) {
          let key = device?.connector?.id + "*" + device.id;
          if (!(key in inserted)) {
            inserted[key] = true;
            let connector = srvEquipment.equipmentAdapter(
              device.connector,
              srvConnector
            );
            let extendedProperties = srvEquipment.getExtendedProperties(
              connector
            );
            Object.assign(connector, extendedProperties);
            context.commit("SET_BASIC_EQUIPMENT_PROPERTIES", {
              equipment: connector,
              device: item.device
            });
          }
        }
      });
    },

    fetchEquipmentDataList(context, query) {
      if (
        context.state.equipmentDataListLoadingId &&
        context.state.equipmentDataListLoadingId == query.id
      ) {
        // already loading the same data set
        return;
      }
      context.commit("SET_EQUIPMENT_DATA_LIST_LOADING_ID", query.id);
      let srv = new EquipmentDataService();
      srv.fetch({connector_id: query.id}).then(
        (response) => {
          if (response != null) {
            if (response) {
              context.commit("SET_EQUIPMENT_DATA_LIST", response);
              context.dispatch("parseDataConnector", response);
            } else {
              context.commit("SET_EQUIPMENT_DATA_LIST", []);
            }
          }
          context.commit("SET_EQUIPMENT_DATA_LIST_LOADING_ID", 0);
        },
        () => {
          if (
            (context.state.equipmentDataList || []).find(
              (item) => item.clp_id != query.id
            )
          ) {
            // once current datalist belongs to another connector, reset it!
            context.commit("SET_EQUIPMENT_DATA_LIST", []);
          }
          context.commit("SET_EQUIPMENT_DATA_LIST_LOADING_ID", 0);
        }
      );
    },

    fetchAllAlarmsCfg(context) {
      var self = this;
      let srv = new EquipmentAlarmService();
      let lst = self.state.equipmentList.map(function(i) {
        return i.id;
      });
      for (var i in lst) {
        srv.fetch({connector_id: lst[i]}).then((data) => {
          if (data) {
            context.commit("SET_EQUIPMENT_ALARMS_CFG", data);
          }
        });
      }
    },

    fetchEquipmentDataAlarmsCfg(context, query) {
      var self = this;
      if (self.getters["isLoadingAlarm"]) return;
      context.commit("SET_ALARMS_LOADING", true);
      context.commit("SET_ALARMS_READY", false);
      let srv = new EquipmentAlarmService();
      srv.fetch({connector_id: query.id}).then((data) => {
        if (data) {
          context.commit("SET_EQUIPMENT_ALARMS_CFG", data);
        }
        context.commit("SET_ALARMS_READY", true);
        context.commit("SET_ALARMS_LOADING", false);
      });
    },

    setEquipmentDisplay(context, entry) {
      let equipment = (context.state.equipmentList || []).find(
        (i) => i.id == entry.equipmentId
      );
      if (equipment) {
        let cfg = (Vue.http.options && Vue.http.options.config) || {};
        let screenId =
          ("screenId" in entry ? entry.screenId : equipment.screen_id) ||
          cfg.screen_id ||
          0;
        let template = context.getters["dashboard/template"](screenId);
        if (
          !template &&
          (context.getters["dashboard/screens"] || []).length &&
          (cfg?.screens || []).length
        ) {
          let defScreen = cfg.screens.find((i) => "default" in i && i.default);
          if (!defScreen) {
            defScreen = cfg.screens.screens[0];
          }
          if (defScreen) {
            template = context.getters["dashboard/template"](defScreen.id);
            if (template) {
              entry.screenId = defScreen.id;
            } else {
              let screen = context.getters["dashboard/screen"](defScreen.id);
              if (screen) {
                context
                  .dispatch("dashboard/fetchTemplate", defScreen.id)
                  .then((result) => {
                    if (result) {
                      entry.screenId = defScreen.id;
                      context.commit("SET_DISPLAY", entry);
                    }
                  });
              }
              return;
            }
          }
        }
      }
      context.commit("SET_DISPLAY", entry);
    },

    setEquipmentNotification(context, option) {
      var lst = context.state.equipmentList.filter(function(i) {
        return i.id == option.equipment.id && option.notifications;
      });
      var equipment = (lst.length && lst[0]) || null;
      if (equipment) {
        context.commit("SET_EQUIPMENT_NOTIFICATION", option);
      }
    },

    setPrint(context, value) {
      context.commit("SET_PRINT", value);
    }
  }
});
